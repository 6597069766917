
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default defineComponent({
  props: ["isBrowseReport"],
  setup() {
    const store = useStore();

    const config = computed(() => {
      return store.state.ReportModule.orderReportConfig;
    });

    const originalConfig = computed(() => {
      return store.state.ReportModule.originalOrderConfig;
    });

    return {
      originalConfig,
      config,
      moment,
    };
  },
});
