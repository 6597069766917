
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { Search } from "@element-plus/icons-vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: ["isBrowseReport"],
  components: {
    Search,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const searchForm = computed(() => {
      return store.state.BillModule.filterBillListForm;
    });

    const options = [
      {
        value: -1,
        label: "All Status",
      },
      {
        value: 1,
        label: "Pending",
      },
      {
        value: 3,
        label: "Overdue",
      },
    ];

    return {
      searchForm,
      moment,
      options,
      route,
    };
  },
});
