
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Search } from "@element-plus/icons-vue";

export default defineComponent({
  props: ["isBrowseReport"],
  components: {
    Search,
  },
  setup() {
    interface Selection {
      value: number;
      name: string;
    }
    const store = useStore();

    const config = computed(() => {
      return store.state.ReportModule.riderReportConfig;
    });

    const originalConfig = computed(() => {
      return store.state.ReportModule.originalRiderConfig;
    });

    const riderList = computed(() => {
      return store.getters["RiderModule/getRiderListSelection"];
    });

    // eslint-disable-next-line
    const querySearchAsync = (queryString: string, cb: (arg: any) => void) => {
      const results = queryString
        ? riderList.value.filter(createFilter(queryString))
        : riderList.value;

      cb(results);
    };

    const createFilter = (queryString: string) => {
      return (rider: Selection) => {
        return (
          rider.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    };

    const options = [
      {
        value: 1,
        label: "Active",
      },
      {
        value: 2,
        label: "Pending",
      },
      {
        value: 3,
        label: "Inactive",
      },
    ];

    async function fetchRiderList() {
      store.commit("RiderModule/" + Mutations.SET_RIDER_LIST, []);
      await store.dispatch(
        "RiderModule/" + Actions.FETCH_RIDER_LIST,
        config.value.riderStatus
      );
    }
    onMounted(() => {
      fetchRiderList();
    });

    return {
      originalConfig,
      config,
      moment,
      querySearchAsync,
      options,
      fetchRiderList,
    };
  },
});
