
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { useRoute } from "vue-router";
import { Search } from "@element-plus/icons-vue";
export default defineComponent({
  props: ["isBrowseReport"],
  setup() {
    const store = useStore();
    const route = useRoute();

    const searchForm = computed(() => {
      return store.state.BankModule.filterBankListForm;
    });
    return {
      searchForm,
      moment,
      route,
      Search,
    };
  },
});
