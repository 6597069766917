
import { defineComponent, computed, onMounted } from "vue";
// import KTSearch from "@/layout/header/partials/Search.vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "topbar",
  components: {
    // KTSearch,
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userImage = computed(() => {
      return window.localStorage.getItem("user_image");
    });

    const isAuthenticated = computed(() => {
      return store.state.AuthModule.isAuthenticated;
    });

    const userCreditWallet = computed(() => {
      return store.state.AuthModule.userCreditWallet;
    });

    function toWalletTransactionPage() {
      router.push({ path: "/management/wallet-transaction" });
    }

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */

      if (isAuthenticated.value) {
        store.dispatch(Actions.FETCH_USER_INFO);
        let result = setInterval(function () {
          store.dispatch(Actions.FETCH_USER_INFO);
        }, 30000);
        store.commit(Mutations.SET_INTERVAL_CREDIT_WALLET, result);
      }
    });

    return {
      userImage,
      userCreditWallet,
      toWalletTransactionPage,
    };
  },
});
