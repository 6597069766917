
import { defineComponent, onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
export default defineComponent({
  name: "kt-menu",
  data() {
    return {
      //notification check
      check: true,
    };
  },
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();
    const checknoti = ref(false);
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      init();
    });
    async function init() {
      //await store.dispatch("RiderModule/" + Actions.FETCH_RIDER_LIST);
      await store.dispatch("BillModule/" + Actions.FETCH_COUNT_BILL);
    }
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const setbill = computed(() => {
      return store.state.BillModule.setbill; //เข้าไปที่ state.ตัวแปรข้อมูล
    });
    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      setbill,
      checknoti,
    };
  },
});
