import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0a5fcd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar__content" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.route.name == 'bank_informationList')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_el_input, {
              clearable: "",
              modelValue: _ctx.searchForm.bank_text,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.bank_text) = $event)),
              placeholder: "Search bank name / bank number",
              style: {"width":"100%"},
              "value-key": "name",
              "prefix-icon": _ctx.Search
            }, null, 8, ["modelValue", "prefix-icon"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}