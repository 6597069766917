
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { Search } from "@element-plus/icons-vue";
//import TopupFormModal from "@/components/toolbar/TopupFormModal.vue";
import { useRoute } from "vue-router";
// import TopupFormModal from "../TopupFormModal.vue";
export default defineComponent({
  props: ["isBrowseReport"],
  components: {
    Search,

    // TopupFormModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const searchForm = computed(() => {
      return store.state.WalletModule.filterTransactionForm;
    });

    const options = [
      {
        value: -1,
        label: "All Status",
      },
      {
        value: 1,
        label: "Active",
      },
      {
        value: 2,
        label: "Pending",
      },
      {
        value: 3,
        label: "Inactive",
      },
    ];

    return {
      searchForm,
      moment,
      options,
      route,
    };
  },
});
